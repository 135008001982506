<template>
  <b-modal centered modal-class="delete-utm-modal"
           id="delete-utm-modal" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('delete-utm-modal')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="42" viewBox="0 0 38 42" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6125 3.92729C14.0233 3.5165 14.5805 3.28571 15.1614 3.28571H22.8281C23.4091 3.28571 23.9662 3.5165 24.377 3.92729C24.7878 4.33808 25.0186 4.89524 25.0186 5.47619V7.6665H12.971V5.47619C12.971 4.89524 13.2017 4.33808 13.6125 3.92729ZM9.68524 7.6665V5.47619C9.68524 4.02381 10.2622 2.63092 11.2892 1.60394C12.3162 0.576954 13.7091 0 15.1614 0H22.8281C24.2805 0 25.6734 0.576954 26.7004 1.60394C27.7273 2.63092 28.3043 4.02381 28.3043 5.47619V7.6665H36.25C37.1573 7.6665 37.8928 8.40204 37.8928 9.30936C37.8928 10.2167 37.1573 10.9522 36.25 10.9522H34.0543V36.1429C34.0543 37.5952 33.4773 38.9881 32.4504 40.0151C31.4234 41.0421 30.0305 41.6191 28.5781 41.6191H9.41143C7.95906 41.6191 6.56617 41.0421 5.53918 40.0151C4.5122 38.9881 3.93524 37.5952 3.93524 36.1429V10.9522H1.74997C0.842649 10.9522 0.107117 10.2167 0.107117 9.30936C0.107117 8.40204 0.842649 7.6665 1.74997 7.6665H9.68524ZM7.22096 36.1429V10.9524H30.7686V36.1429C30.7686 36.7238 30.5378 37.281 30.127 37.6918C29.7162 38.1026 29.1591 38.3333 28.5781 38.3333H9.41143C8.83048 38.3333 8.27333 38.1026 7.86253 37.6918C7.45174 37.281 7.22096 36.7238 7.22096 36.1429ZM15.164 17.25C16.0714 17.25 16.8069 17.9855 16.8069 18.8929V30.3929C16.8069 31.3002 16.0714 32.0357 15.164 32.0357C14.2567 32.0357 13.5212 31.3002 13.5212 30.3929V18.8929C13.5212 17.9855 14.2567 17.25 15.164 17.25ZM24.471 18.8929C24.471 17.9855 23.7354 17.25 22.8281 17.25C21.9208 17.25 21.1852 17.9855 21.1852 18.8929V30.3929C21.1852 31.3002 21.9208 32.0357 22.8281 32.0357C23.7354 32.0357 24.471 31.3002 24.471 30.3929V18.8929Z" fill="#C13A52"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[16px] font-bold leading-5">Are you Sure?</p>
        <p class="pt-2 text !text-[14px] text-center">Do you want to delete ? This process can not be undone.</p>
        <div class="pt-10 pb-11 flex gap-x-3 justify-between items-center">
          <Button
              id="login-button"
              type="button"
              class="!border-[1.5px] !border-gray-500 !rounded-[8px] !text-[14px] hover:bg-[#F2F3F8]"
              buttonClass="btn-lg"
              @click="$bvModal.hide('delete-utm-modal')"
          >
            <template v-slot:label>Cancel</template>
          </Button>
          <Button
              id="login-button"
              type="button"
              class="text-white bg-[#C13A52] !rounded-[8px] !text-[14px]"
              buttonClass="btn-lg"
              @click.prevent="deleteUTMFunction()"
          >
            <template v-if="!loader" v-slot:label>Delete</template>
            <template v-if="loader" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, } from 'vuex'
export default {
  props: ['DelObj'],
  data () {
    return {
      loader: false
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  methods: {
    ...mapActions(['removeUTM']),


    async deleteUTMFunction () {
      this.loader = true
      await this.removeUTM(this.DelObj)
      this.loader = false
      this.$bvModal.hide('delete-utm-modal')
    },
  }
}
</script>
<style lang="less">
.delete-utm-modal {
  .modal-dialog {
    max-width: 28.35rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }
  .modal-body {
    padding: 0 !important;
  }
}

</style>
