<template>
  <b-modal
    modal-class="modal_basic add_UTM_Modal"
    id="addUtmModal"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header
  >
    <div class="w-full h-full">
      <div
        class="w-full h-full flex flex-col items-center justify-between pt-2 gap-6"
      >
        <div class="flex flex-col items-center justify-center">
          <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28 15.2509C28 25.362 15 34.0286 15 34.0286C15 34.0286 2.00005 25.362 2.00005 15.2509C2.00005 11.8031 3.36968 8.49654 5.80764 6.05858C8.24561 3.62061 11.5522 2.25098 15 2.25098C18.4478 2.25098 21.7544 3.62061 24.1924 6.05858C26.6303 8.49654 28 11.8031 28 15.2509Z" stroke="#2560D7" stroke-width="2.58194" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 19.5836C17.3932 19.5836 19.3333 17.6435 19.3333 15.2503C19.3333 12.8571 17.3932 10.917 15 10.917C12.6068 10.917 10.6667 12.8571 10.6667 15.2503C10.6667 17.6435 12.6068 19.5836 15 19.5836Z" stroke="#2560D7" stroke-width="2.58194" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          <p
            class="text-[#3C4549] font-poppins pt-[1.188rem] text-[14px] font-bold leading-5"
          >
            {{
              getCreateUTM._id
                ? "Update your UTM Preset"
                : "Create your UTM Preset"
            }}
          </p>

          <p class="pt-1 text !text-[14px] text-center">
            Please enter details to add UTM preset.
          </p>
        </div>

        <div class="flex flex-col justify-center item-center w-full gap-3">
          <FloatingLabelInput
            id="Pixel-Campaign"
            @enter="validateAndStorePixel()"
            :errorType="
              validations.campaign || validations.campaignLength ? 'danger' : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="UTM Campaign"
            placeholder="Product, promo code, or slogan (e.g. spring_sale)"
            v-model="getCreateUTM.campaign"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.campaign"
                :message="messages.campaign"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.campaignLength"
                :message="messages.campaignLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Pixel-Medium"
            @enter="validateAndStorePixel()"
            :errorType="
              validations.medium || validations.mediumLength ? 'danger' : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="UTM Medium"
            placeholder="Marketing Medium (e.g. social)"
            v-model="getCreateUTM.medium"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.medium"
                :message="messages.medium"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.mediumLength"
                :message="messages.mediumLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Pixel-Source"
            @enter="validateAndStorePixel()"
            :errorType="
              validations.source || validations.sourceLength ? 'danger' : ''
            "
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="UTM Source"
            placeholder="The referrer (e.g. facebook, twitter)"
            v-model="getCreateUTM.source"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.source"
                :message="messages.source"
              ></InputFieldMessage>
              <InputFieldMessage
                v-else-if="validations.sourceLength"
                :message="messages.sourceLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Pixel-Term"
            @enter="validateAndStorePixel()"
            :errorType="validations.termLength ? 'danger' : ''"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="UTM Term"
            placeholder="e.g paid keywords"
            v-model="getCreateUTM.term"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.termLength"
                :message="messages.termLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>

          <FloatingLabelInput
            id="Pixel-Content"
            @enter="validateAndStorePixel()"
            :errorType="validations.contentLength ? 'danger' : ''"
            class="mb-3 w-full"
            customClass="h-[56px] bg-[#F4F6FA]"
            type="text"
            label="UTM Content"
            placeholder="e.g text AD name"
            v-model="getCreateUTM.content"
          >
            <template v-slot:error_message>
              <InputFieldMessage
                v-if="validations.contentLength"
                :message="messages.contentLength"
              ></InputFieldMessage>
            </template>
          </FloatingLabelInput>
        </div>
        <div class="mt-2 flex gap-x-3 justify-between items-center">
          <Button
            id="close-button"
            type="button"
            class="!border-[1.5px] !border-[#3C4549] !py-[1.142rem] text-[14px] !rounded-[0.571rem] hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="close()"
          >
            <template v-slot:label>Close</template>
          </Button>

          <Button
            id="store-button"
            type="button"
            class="text-white bg-[#2560D7] !py-[1.142rem] text-[14px] !rounded-[0.571rem] hover:bg-blue-700"
            buttonClass="btn-lg"
            @click.prevent="saveUTM()"
            :disabledProp="loaders.save"
          >
            <template v-slot:label>Save</template>
            <template v-if="loaders.save" v-slot:loader>
              <Loader></Loader>
            </template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
    FloatingLabelInput: () => import("@/ui/ui-kit/v2/FloatingLabelInput.vue"),
    InputFieldMessage: () => import("@/ui/ui-kit/v2/InputFieldMessage.vue"),
  },
  data() {
    return {
      size: "14px",
      color: "#fff",
      loaders: {
        save: false,
      },
      validations: {
        name: false,
        nameLength: false,
        medium: false,
        mediumLength: false,
        source: false,
        sourceLength: false,
        campaign: false,
        campaignLength: false,
        contentLength: false,
        termLength: false,
      },
      messages: {
        name: "UTM Name is required.",
        nameLength: "The name should not be greater than 35 characters.",
        medium: "Medium Name is required.",
        mediumLength: "The medium should not be greater than 100 characters.",
        source: "Source name is required.",
        sourceLength: "The source should not be greater than 100 characters.",
        campaign: "Campaign name is required.",
        campaignLength:
          "The campaign should not be greater than 100 characters.",
        contentLength:
          "The content should not be greater than 2000 characters.",
        termLength: "The term should not be greater than 2000 characters.",
      },
    };
  },
  computed: {
    ...mapGetters(["getCreateUTM"]),
  },
  methods: {
    ...mapActions(["storeUTM"]),
    async saveUTM() {
      // this.validations.name = this.requiredCheck(this.getCreateUTM.name)
      this.validations.medium = this.requiredCheck(this.getCreateUTM.medium);
      this.validations.source = this.requiredCheck(this.getCreateUTM.source);
      this.validations.campaign = this.requiredCheck(
        this.getCreateUTM.campaign
      );
      let result = Object.keys(this.validations).every(
        (k) => this.validations[k] === false
      );
      if (result) {
        this.loaders.save = true;
        await this.storeUTM();
        this.loaders.save = false;
        this.$bvModal.hide("addUtmModal");
      }
    },
    close() {
      this.$bvModal.hide("addUtmModal");
      this.validations = {
        name: false,
        nameLength: false,
        medium: false,
        mediumLength: false,
        source: false,
        sourceLength: false,
        campaign: false,
        campaignLength: false,
        contentLength: false,
        termLength: false,
      };
    },
  },
  watch: {
    "getCreateUTM.name"(value) {
      if (value && value.length > 0) {
        this.validations.name = false;
        this.validations.nameLength = !this.maxLength(value, 35);
      }
    },
    "getCreateUTM.medium"(value) {
      if (value && value.length > 0) {
        this.validations.medium = false;
        this.validations.mediumLength = !this.maxLength(value, 100);
      }
    },
    "getCreateUTM.source"(value) {
      if (value && value.length > 0) {
        this.validations.source = false;
        this.validations.sourceLength = !this.maxLength(value, 100);
      }
    },
    "getCreateUTM.campaign"(value) {
      if (value && value.length > 0) {
        this.validations.campaign = false;
        this.validations.campaignLength = !this.maxLength(value, 100);
      }
    },
    "getCreateUTM.term"(value) {
      if (value && value.length > 0) {
        this.validations.termLength = !this.maxLength(value, 2000);
      }
    },
    "getCreateUTM.content"(value) {
      if (value && value.length > 0) {
        this.validations.contentLength = !this.maxLength(value, 2000);
      }
    },
  },
};
</script>
<style lang="less">
.add_UTM_Modal {
.modal-dialog {
max-width: 36rem !important;
}

.modal-body {
padding: 40px 40px 58px 40px !important;
}
}
</style>
